import { SensorType } from "../proto/common";
import * as theme from "../assets/styles/Variables";
import { Body2 } from "../assets/styles";
import { Tile } from "../assets/styles/Device";
import { TemperatureIcon } from "../assets/icons/TemperatureIcon";
import { HumidityIcon } from "../assets/icons/HumidityIcon";
import { PressureIcon } from "../assets/icons/PressureIcon";
import { ISensorReadingsSource } from "../shared/services/SensorReadingsSource";
import React, { Suspense } from "react";
import { DataChart } from "./DataChart";

const sensorTypeToTitle = new Map<SensorType, string>([
  [SensorType.TEMPERATURE, "Temperature"], 
  [SensorType.HUMIDITY, "Humidity"], 
  [SensorType.ATMOSPHERIC_PRESSURE, "Atmospheric Pressure"], 
  [SensorType.ACCEL_X, "Acceleration X"], 
  [SensorType.ACCEL_Y, "Acceleration Y"], 
  [SensorType.ACCEL_Z, "Acceleration Z"], 
  [SensorType.CURRENT, "Current"],
  [SensorType.VOLTAGE, "Voltage"],
])

const sensorTypeToIcon = new Map<SensorType, React.ComponentType>([
  [SensorType.TEMPERATURE, TemperatureIcon],
  [SensorType.HUMIDITY, HumidityIcon], 
  [SensorType.ATMOSPHERIC_PRESSURE, PressureIcon], 
  [SensorType.ACCEL_X, PressureIcon], 
  [SensorType.ACCEL_Y, PressureIcon], 
  [SensorType.ACCEL_Z, PressureIcon], 
  [SensorType.CURRENT, PressureIcon],
  [SensorType.VOLTAGE, PressureIcon],
])

export interface IDataTileProps {
  source: ISensorReadingsSource,
  sensorType: SensorType
}

export const DataTile = (props: IDataTileProps) => {
  return (
    <Tile style={{ backgroundColor: theme.TILE_COLOR, marginBottom: "14px" }}>
      <div
        style={{
          marginLeft: "15px",
          marginTop: "12px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >

        {React.createElement(sensorTypeToIcon.get(props.sensorType) ?? PressureIcon)}
        <Body2 dark style={{ marginLeft: "10px" }}>
          {sensorTypeToTitle.get(props.sensorType) ?? "Unknown"}
        </Body2>
      </div>
      <div
        style={{
          height: "450px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "14px",
        }}
      >
        <Suspense>
          <DataChart
            sensorType={props.sensorType}
            source={props.source}
          />
        </Suspense>
      </div>
    </Tile>
  );
};
