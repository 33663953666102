import { GetDeviceDetailsResponse } from "../../proto/device_service";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import { ConfigParams, SensorType } from "../../proto/common";
import { duration } from "moment";
import { ISensorReadingsSource, StaticSensorReadingsSource, StreamingSensorReadingsSource } from "./SensorReadingsSource";
import { DeviceServices } from "./DeviceManager";

export enum DeviceType {
  Aeolus,
  Diialog,
  Unknown,
}

export class Device {
  private readonly deviceServices: DeviceServices;

  public readonly id: string;
  public readonly type: DeviceType;

  private readonly detailsSubject$ = new BehaviorSubject<GetDeviceDetailsResponse | null>(null);
  public readonly details$: Observable<GetDeviceDetailsResponse | null> = this.detailsSubject$;

  public get lastDetails() {
    return this.detailsSubject$.value
  }

  readonly requestedConfig$ = new Observable<ConfigParams>(x => { this.getRequestedConfig(x); });

  constructor(details: GetDeviceDetailsResponse, deviceServices: DeviceServices) {
    this.deviceServices = deviceServices;

    this.id = details.id;

    this.detailsSubject$.next(details);

    if (this.id.startsWith("diialog")) {
      this.type = DeviceType.Diialog;
    }
    else if (this.id.startsWith("aeolus")) {
      this.type = DeviceType.Aeolus;
    }
    else {
      this.type = DeviceType.Unknown;
    }
  }

  public updateDetails(details: GetDeviceDetailsResponse) {
    this.detailsSubject$.next(details);
  }

  private async getRequestedConfig(obs: Subscriber<ConfigParams>) {
    console.log(`Fetching requested config for ${this.id}`);
    const abortController = new AbortController();
    obs.add(() => {
      abortController.abort();
    });
    try {
      const call = this.deviceServices.deviceClient.getRequestedDeviceConfig(
        {
          id: [this.id],
        },
        {
          meta: await this.deviceServices.getMeta(),
          abort: abortController.signal
        }
      );
      call.responses.onMessage(x => {
        console.log("New requested config:", x);
        obs.next(x.configParams!);
      });
    } catch (error) {
      console.log(`Unable to fetch requested config: ${error}`);
    }
  }

  public async setRequestedConfig(config: ConfigParams) {
    try {
      await this.deviceServices.deviceClient.setRequestedDeviceConfig(
        {
          id: this.id,
          configParams: config,
        },
        {
          meta: await this.deviceServices.getMeta(),
        }
      );
    } catch (error) {
      console.log(`Unable to set requested device config: ${error}`);
    }
  }

  public createSensorReadingsSource(): ISensorReadingsSource {
    if (this.type == DeviceType.Aeolus) {
      return new StaticSensorReadingsSource(this.id, [
        SensorType.TEMPERATURE,
        SensorType.HUMIDITY,
        SensorType.ATMOSPHERIC_PRESSURE,
      ], this.deviceServices);
    }

    if (this.type == DeviceType.Diialog) {
      return new StreamingSensorReadingsSource(this.id, [
        SensorType.ATMOSPHERIC_PRESSURE,
        SensorType.CURRENT,
        SensorType.VOLTAGE,
        SensorType.ACCEL_X,
        SensorType.ACCEL_Y,
        SensorType.ACCEL_Z
      ], duration(5, 'minutes'), this.deviceServices);
    }

    throw Error("Unknown device type");
  }
}
