import * as theme from "../styles/Variables";
import Icon from "./Icon";
import styled from "styled-components";

const Svg = styled(Icon)`
    width: 26.5px;
    height: 24px;
    fill: ${theme.DATA_ICON_COLOR}
`

export const PressureIcon = () => {
    return (
    <Svg xmlns="http://www.w3.org/2000/svg">
        <path xmlns="http://www.w3.org/2000/svg" d="M20.6315 24.8086C18.7986 24.8086 17.2359 24.1584 15.9436 22.8579C14.6513 21.5575 14.0056 19.9855 14.0065 18.1419C14.0065 16.2975 14.6526 14.725 15.9449 13.4246C17.2373 12.1242 18.7994 11.4744 20.6315 11.4753C22.4644 11.4753 24.027 12.1255 25.3193 13.4259C26.6116 14.7264 27.2574 16.2984 27.2565 18.1419C27.2565 19.9864 26.6103 21.5588 25.318 22.8593C24.0257 24.1597 22.4635 24.8095 20.6315 24.8086ZM20.8965 18.8086L23.9108 15.7753L22.9833 14.8419L19.969 17.8753L20.8965 18.8086ZM0.756472 10.1419V3.47526C0.756472 2.74193 1.01617 2.11393 1.53557 1.59126C2.05497 1.0686 2.67861 0.807707 3.40647 0.808596H24.6065C25.3352 0.808596 25.9593 1.06993 26.4787 1.5926C26.9981 2.11526 27.2574 2.74282 27.2565 3.47526V11.6086C26.3731 10.6975 25.3626 10.0033 24.2249 9.52593C23.0871 9.0486 21.8893 8.80948 20.6315 8.8086C19.7923 8.8086 18.9916 8.91437 18.2292 9.12593C17.4669 9.33748 16.7439 9.63171 16.0602 10.0086L13.874 5.54193C13.7636 5.31971 13.5979 5.15304 13.3771 5.04193C13.1563 4.93082 12.9244 4.87526 12.6815 4.87526C12.4386 4.87526 12.2067 4.93082 11.9858 5.04193C11.765 5.15304 11.5994 5.31971 11.489 5.54193L7.38147 13.8419L5.89085 10.8753C5.78043 10.653 5.62011 10.4753 5.40987 10.3419C5.19964 10.2086 4.97351 10.1419 4.73147 10.1419H0.756472ZM3.40647 22.1419C2.67772 22.1419 2.05365 21.8806 1.53425 21.3579C1.01485 20.8353 0.755589 20.2077 0.756472 19.4753V12.8086H3.90335L6.18897 17.4086C6.29939 17.653 6.45971 17.8366 6.66995 17.9593C6.88018 18.0819 7.11736 18.1428 7.38147 18.1419C7.62439 18.1419 7.85096 18.0806 8.0612 17.9579C8.27143 17.8353 8.43131 17.6522 8.54085 17.4086L12.6815 9.14193L13.9402 11.6753C13.1452 12.5197 12.5158 13.4922 12.0521 14.5926C11.5883 15.693 11.3565 16.8762 11.3565 18.1419C11.3565 18.8308 11.4285 19.5144 11.5724 20.1926C11.7164 20.8708 11.9426 21.5206 12.2508 22.1419H3.40647Z"/>
    </Svg>
    )
}