import { IDeviceServiceClient } from "../../proto/device_service.client";
import { BehaviorSubject, Observable } from "rxjs";
import Immutable from "immutable";
import { ISensorServiceClient } from "../../proto/sensor_service.client";
import { Device } from "./Device";


export class DeviceManager {
  private readonly devicesSubject$ = new BehaviorSubject<Immutable.Map<string, Device>>(Immutable.Map<string, Device>());
  public readonly devices$: Observable<Immutable.Map<string, Device>> = this.devicesSubject$;

  private deviceIdsAbort: AbortController | null = null;
  private deviceDetailsAbort: AbortController | null = null;
  private tokenProvider: (() => Promise<string>) | null = null;

  private readonly deviceClient: IDeviceServiceClient;
  private readonly sensorClient: ISensorServiceClient;

  constructor(deviceClient: IDeviceServiceClient, sensorClient: ISensorServiceClient) {
    this.deviceClient = deviceClient;
    this.sensorClient = sensorClient;
  }

  async start(tokenProvider: () => Promise<string>) {
    this.deviceIdsAbort?.abort();
    this.deviceIdsAbort = new AbortController();
    this.tokenProvider = tokenProvider;

    try {
      const call = this.deviceClient.getDeviceIds(
        {},
        {
          meta: await this.getMeta(),
          abort: this.deviceIdsAbort.signal,
        });
      call.responses.onMessage(x => this.handleNewDeviceIds(x.id));
    }
    catch (error) {
      console.log("Error streaming device ids: ", error);
    }
  }

  private async handleNewDeviceIds(deviceIds: string[]) {
    console.log(`Got devices: ${deviceIds}`);
    this.deviceDetailsAbort?.abort();
    this.deviceDetailsAbort = new AbortController();

    const deviceServices = new DeviceServices(this.deviceClient, this.sensorClient, this.tokenProvider!);

    try {
      const call = this.deviceClient.getDeviceDetails(
        { id: deviceIds },
        {
          meta: await this.getMeta(),
          abort: this.deviceDetailsAbort.signal,
        }
      );

      call.responses.onMessage(x => {
        const devices = this.devicesSubject$.value;
        const existingDevice = devices.get(x.id);
        if (existingDevice == undefined) {
          const newDevices = devices.set(x.id, new Device(x, deviceServices));
          this.devicesSubject$.next(newDevices);
        }
        else {
          existingDevice.updateDetails(x);
        }
      });
    }
    catch (error) {
      console.log("Error streaming device details: ", error);
    }
  }

  private async getMeta(): Promise<any> {
    return {
      Authorization: "Bearer " + (await this.tokenProvider!()),
    };
  }
}

export class DeviceServices {
  readonly deviceClient: IDeviceServiceClient
  readonly sensorClient: ISensorServiceClient 
  readonly tokenProvider: () => Promise<string>

  constructor(
      deviceClient: IDeviceServiceClient,
      sensorClient: ISensorServiceClient,
      tokenProvider:() => Promise<string>) {
    this.deviceClient = deviceClient
    this.sensorClient = sensorClient
    this.tokenProvider = tokenProvider
  }

  async getMeta(): Promise<any> {
    return {
      Authorization: "Bearer " + (await this.tokenProvider!()),
    }
  }
}
