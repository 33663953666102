import * as theme from "../assets/styles/Variables";
import moment from "moment";
import {
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { SensorReading, SensorType } from "../proto/common";
import "chartjs-adapter-moment";
import { ISensorReadingsSource } from "../shared/services/SensorReadingsSource";
import { useEffect, useState } from "react";

interface IChartSettings {
  xLabel: string
  yMax?: number
  yMin?: number
}

// These max values come from a demo -- we might need a way to tweak these per device type somehow!
const sensorTypeToChartSettings = new Map<SensorType, IChartSettings>([
  [SensorType.TEMPERATURE, {
    xLabel: "Temperature (\xB0C)",
  }], 
  [SensorType.HUMIDITY, {
    xLabel: "Humidity (%)",
  }], 
  [SensorType.ATMOSPHERIC_PRESSURE, {
    xLabel: "Atmospheric Pressure (%atm)",
  }], 
  [SensorType.ACCEL_X, {
    xLabel: "Acceleration X",
    yMin: -10,
    yMax: 10,
  }], 
  [SensorType.ACCEL_Y, {
    xLabel: "Acceleration X",
    yMin: -10,
    yMax: 10,
  }], 
  [SensorType.ACCEL_Z, {
    xLabel: "Acceleration Z",
    yMin: -10,
    yMax: 10,
  }], 
  [SensorType.CURRENT, {
    xLabel: "Current (A)",
    yMin: 0,
    yMax: 3,
  }],
  [SensorType.VOLTAGE, {
    xLabel: "Voltage (V)",
    yMin: 0,
    yMax: 3,
  }],
])

export interface IStreamingDataChartProps {
  sensorType: SensorType
  source: ISensorReadingsSource
}

export const DataChart = (props: IStreamingDataChartProps) => {
  const [dataPoints, setDataPoints] = useState<SensorReading[]>([])

  useEffect(() => {
    // We want to explicitly unsubscribe when we're unloaded
    const subscription = props.source.data.get(props.sensorType)?.subscribe(x => setDataPoints(x))
    return () => subscription?.unsubscribe()
  }, [props.source])

  const chartSettings = sensorTypeToChartSettings.get(props.sensorType)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale
  );

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        top: 0,
        right: 16,
        bottom: 0,
      },
    },
    plugins: {
      decimation: {
        enabled: true,
        algorithm: "lttb",
      },
    },
    normalized: true,
    animation: false,
    scales: {
      x: {
        type: "time",
        ...(!props.source.isStreaming && { time: { "unit": "day" } }),
        // border: {
        //     display: true,
        // },
        // alignToPixels: true,
        // display: true,
        title: {
          display: true,
          text: "Date",
          color: theme.GRAY_COLOR,
          font: {
            family: "Inter",
            size: 12,
          },
        },
        grid: {
          display: true,
          color: theme.LIGHT_COLOR,
          drawTicks: false,
        },
        ticks: {
          font: {
            size: 10,
            family: "Inter",
          },
          color: theme.BLACK_COLOR,
        },
      },
      y: {
        ...(chartSettings?.yMin != undefined && { suggestedMin: chartSettings!.yMin }),
        ...(chartSettings?.yMax != undefined && { suggestedMax: chartSettings!.yMax }),
        // border: {
        //     display: true,
        // },
        // alignToPixels: true,
        // display: true,
        title: {
          display: true,
          text: chartSettings?.xLabel ?? "Unknown",
          color: theme.GRAY_COLOR,
          font: {
            family: "Inter",
            size: 12,
          },
        },
        grid: {
          display: true,
          color: theme.LIGHT_COLOR,
          drawTicks: false,
        },
        ticks: {
          font: {
            size: 10,
            family: "Inter",
          },
          color: theme.BLACK_COLOR,
        },
      },
    },
  };

  const chartData: ChartData<"line"> = {
    labels: dataPoints.map((reading) => {
      const timestamp = moment(Number(reading.timestamp?.milliseconds));
      return props.source.isStreaming ? timestamp : timestamp.format("MMM DD")
    }),
    datasets: [
      {
        data: dataPoints.map((reading) => {
          return reading.value;
        }),
        pointRadius: 0,
        borderColor: theme.BLACK_COLOR,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Line
      style={{
        maxWidth: "450px",
        maxHeight: "380px",
        backgroundColor: "#FFFFFF",
      }}
      options={chartOptions}
      data={chartData}
    />
  );
};
