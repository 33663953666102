import styled from "styled-components";

interface DeviceProps {
  readonly height?: string;
}

export const DeviceHeading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const DeviceStatusCollapsed = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  width: 1000%;
`;

export const DeviceStatusExpanded = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
`;

export const DeviceFooter = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  width: 600px;
`;

export const DeviceFooterComponent = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Tile = styled.div`
  display:flex;
  flex-direction: column;
  height: 450px;
  width: 480px;
  border-radius: 5px;
`;

export const DeviceStyle = styled.div<DeviceProps>`
  border-radius: 5px;
  overflow: hidden;
  padding: 0px 0px 10px 0px;
  height: ${(props) => props.height};
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  position: relative;
  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: 1px 2px 10px lightgrey;
  }
  & > a {
    text-decoration: none;
  }
`;
