import { ConfigEntry, ConfigRow } from "../assets/styles/Config"
import * as theme from "../assets/styles/Variables";
import { Body3, Heading } from "../assets/styles";
import Divider from '@mui/material/Divider';
import { ConfigurationConstants } from "../shared/constants/ConfigConstants";

interface IConfigProps {
  readonly breakBar?: boolean;
  configLabels: ConfigurationConstants;
  value?: number;
};

export const ConfigDetail = (props: IConfigProps) => {
  return (
    <div className="d-flex flex-direction-row justify-content-center">
      <ConfigEntry>
        <div className="d-flex flex-direction-row justify-content-center">
          <ConfigRow>
            <Body3 info className="d-flex flex-direction-row justify-content-center" style={{width: "75px"}}> {props.configLabels.minValue} - {props.configLabels.maxValue} </Body3>
            <Heading dark lightFont>{props.value}</Heading>
            <Body3 info className="d-flex flex-direction-row justify-content-center" style={{width: "75px"}}> {props.configLabels.units} </Body3>
          </ConfigRow>
        </div>
        <Body3 info className="d-flex flex-direction-row justify-content-center"> {props.configLabels.name} </Body3>
        {props.breakBar && <Divider variant="middle" sx={{ bgcolor: theme.FAINT_GRAY_COLOR, width: "363px", height:"1px", marginTop: "10px", marginBottom:"10px" }} flexItem />}
      </ConfigEntry>
  </div>
  )
}