import {
  ConfigCard,
  ConfigPanel,
  ConfigHeading,
} from "../assets/styles/Config";
import * as theme from "../assets/styles/Variables";
import { Body2, Body3 } from "../assets/styles";
import { ConfigDetail } from "./ConfigDetail";
import Divider from "@mui/material/Divider";
import { ConfigEdit } from "./ConfigEdit";
import { CONFIGURATION_PARAMS } from "../shared/constants/ConfigConstants";
import { useEffect, useState } from "react";
import { ConfigParams } from "../proto/common";
import { UpdateIcon } from "../assets/icons/UpdateIcon";
import { Device } from "../shared/services/Device";
import { bind } from "@react-rxjs/core";

interface IConfigurationupdateProps {
  device: Device
}

const [useDeviceDetails, deviceDetails$] = bind((device: Device) => device.details$)

export const ConfigurationUpdate = (props: IConfigurationupdateProps) => {
  const deviceDetails = useDeviceDetails(props.device)

  const [newRequestedConfig, setNewRequestedConfig] = useState<ConfigParams>({
    publishInterval: 0,
    onDuration: 0,
    offDuration: 0,
  });

  const [requestedConfig, setRequestedConfig] = useState<ConfigParams>();

  function updatePublishingInterval(event: any) {
    console.log("Updated Publishing Interval: ", Number(event.target.value));
    setNewRequestedConfig((oldData: any) => ({
      ...oldData,
      publishInterval: Number(event.target.value),
    }))
  }

  function updateOnDuration(event: any) {
    console.log("Updated On Duration: ", Number(event.target.value));
    setNewRequestedConfig((oldData: any) => ({
      ...oldData,
      onDuration: Number(event.target.value),
    }))
  }

  function updateOffDuration(event: any) {
    console.log("Updated Off Duration: ", Number(event.target.value));
    setNewRequestedConfig((oldData: any) => ({
      ...oldData,
      offDuration: Number(event.target.value),
    }))
  }

  async function exportUpdatedConfigs() {
    console.log("Setting Requested Configs: ", newRequestedConfig);
    if (newRequestedConfig) {
      props.device.setRequestedConfig(newRequestedConfig)
    }
  }
  useEffect(() => {
    const subscription = props.device.requestedConfig$.subscribe(x => setRequestedConfig(x))
    return () => subscription.unsubscribe()
  }, [props.device]);

  return (
    <ConfigCard
      style={{
        backgroundColor: theme.TILE_COLOR,
        borderWidth: "5",
        borderColor: "black",
      }}
    >
      <ConfigPanel>
        <ConfigHeading>
          <Body2 dark>Configuration Update Utility</Body2>
          <div
            className="d-flex flex-direction-row justify-content-center"
            onClick={() => exportUpdatedConfigs()}
            style={{
              cursor: "pointer",
              border: "1px",
              borderStyle: "solid",
              borderRadius: "8px",
              borderColor: theme.LIGHT_GRAY_COLOR,
              marginRight: "30px",
              backgroundColor: theme.VERY_LIGHT_GRAY_COLOR,
              width: "112px",
              height: "40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <UpdateIcon />
              <Body3 bold style={{ marginLeft: "10.25px" }}>
                Update
              </Body3>
            </div>
          </div>
        </ConfigHeading>
        <ConfigEdit
          breakBar
          deviceId={props.device.id}
          configLabels={CONFIGURATION_PARAMS.publishInterval}
          updateCallback={updatePublishingInterval}
          placeHolderValue={0}
        />
        <ConfigEdit
          breakBar
          deviceId={props.device.id}
          configLabels={CONFIGURATION_PARAMS.onDuration}
          updateCallback={updateOnDuration}
          placeHolderValue={0}
        />
        <ConfigEdit
          deviceId={props.device.id}
          configLabels={CONFIGURATION_PARAMS.offDuration}
          updateCallback={updateOffDuration}
          placeHolderValue={0}
        />
      </ConfigPanel>

      <Divider
        orientation="vertical"
        variant="middle"
        sx={{
          bgcolor: theme.GRAY_COLOR,
          width: "1px",
          height: "363px",
          marginTop: "24px",
        }}
        flexItem
      />

      <ConfigPanel>
        <ConfigHeading>
          <Body2 dark>Current Device Configuration Parameters</Body2>
        </ConfigHeading>
        <ConfigDetail
          breakBar
          configLabels={CONFIGURATION_PARAMS.publishInterval}
          value={deviceDetails?.status?.configParams?.publishInterval}
        />
        <ConfigDetail
          breakBar
          configLabels={CONFIGURATION_PARAMS.onDuration}
          value={deviceDetails?.status?.configParams?.onDuration}
        />
        <ConfigDetail
          configLabels={CONFIGURATION_PARAMS.offDuration}
          value={deviceDetails?.status?.configParams?.offDuration}
        />
      </ConfigPanel>

      <Divider
        orientation="vertical"
        variant="middle"
        sx={{
          bgcolor: theme.GRAY_COLOR,
          width: "1px",
          height: "363px",
          marginTop: "24px",
        }}
        flexItem
      />

      <ConfigPanel>
        <ConfigHeading>
          <Body2 dark>Requested Device Configuration Parameters</Body2>
        </ConfigHeading>
        <ConfigDetail
          breakBar
          configLabels={CONFIGURATION_PARAMS.publishInterval}
          value={requestedConfig?.publishInterval}
        />
        <ConfigDetail
          breakBar
          configLabels={CONFIGURATION_PARAMS.onDuration}
          value={requestedConfig?.onDuration}
        />
        <ConfigDetail
          configLabels={CONFIGURATION_PARAMS.offDuration}
          value={requestedConfig?.offDuration}
        />
      </ConfigPanel>
    </ConfigCard>
  );
};
