import * as theme from "../styles/Variables";
import Icon from "./Icon";
import styled from "styled-components";

const Svg = styled(Icon)`
    width: 13.25px;
    height: 26.67px;
    fill: ${theme.DATA_ICON_COLOR}
`

export const TemperatureIcon = () => {
    return (
    <Svg xmlns="http://www.w3.org/2000/svg">
        <path d="M10.875 15.3337V4.66699C10.875 3.60613 10.4562 2.58871 9.71077 1.83857C8.96532 1.08842 7.95426 0.666992 6.90002 0.666992C5.84579 0.666992 4.83473 1.08842 4.08927 1.83857C3.34382 2.58871 2.92502 3.60613 2.92502 4.66699V15.3337C1.81265 16.1732 0.990966 17.3436 0.576372 18.6792C0.161779 20.0148 0.175292 21.4478 0.614998 22.7752C1.0547 24.1026 1.89831 25.2571 3.02632 26.0753C4.15433 26.8934 5.50955 27.3337 6.90002 27.3337C8.2905 27.3337 9.64572 26.8934 10.7737 26.0753C11.9017 25.2571 12.7453 24.1026 13.1851 22.7752C13.6248 21.4478 13.6383 20.0148 13.2237 18.6792C12.8091 17.3436 11.9874 16.1732 10.875 15.3337ZM6.90002 3.33366C7.25144 3.33366 7.58845 3.47413 7.83694 3.72418C8.08543 3.97423 8.22502 4.31337 8.22502 4.66699V8.66699H5.57502V4.66699C5.57502 4.31337 5.71462 3.97423 5.96311 3.72418C6.21159 3.47413 6.54861 3.33366 6.90002 3.33366Z"/>
    </Svg>
    )
}