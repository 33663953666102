export const PRIMARY_COLOR = "#F18759";
export const ACCENT_COLOR = "#FF9A6B";
export const LIGHT_COLOR = "#C3D0CF";
export const DARK_COLOR = "#9F9287";
export const BLACK_COLOR = "#000000";
export const WHITE_COLOR = "#FFFFFF";
export const INFO_COLOR = "#626D7E";
export const YELLOW_COLOR = "#FFE76B";
export const GREEN_COLOR = "#7B8C62";
export const FAINT_GRAY_COLOR = "#E5E7EA";
export const LIGHT_GRAY_COLOR = "#676E76";
export const GRAY_COLOR = "#CED2D6";
export const DARK_GRAY_COLOR = "#454545";
export const BLUETOOTH_COLOR = "#0082FC";
export const CARD_COLOR = "#7B8C62";
export const LIGHT_CARD_COLOR = "#B2BBA6";
export const ORANGE_COLOR = "#FF8C00";
export const BACKGROUND_COLOR = "#44553A";
export const CHART_LINE_COLOR = "#567F3C";
export const TILE_COLOR = "#F7F7F7";
export const DATA_ICON_COLOR = "#C3D0CF";
export const LOGOUT_ICON_COLOR = "#1A1D1F";
export const VERY_LIGHT_GRAY_COLOR = "#FAFAFA"