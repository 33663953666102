import { useAuth0 } from "@auth0/auth0-react";
import { LogoutIcon } from "../assets/icons/LogoutIcon";
import * as theme from "../assets/styles/Variables";
import { Body3 } from "../assets/styles/atoms/Body3";

export const LoginOutButton = (props: any) => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  return !isAuthenticated ? (
    <div
      className="d-flex flex-direction-row justify-content-center"
      onClick={() => loginWithRedirect()}
      style={{
        marginRight: "50px",
        backgroundColor: theme.VERY_LIGHT_GRAY_COLOR,
        width: "116px",
        height: "40px",
        borderRadius: "8px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <LogoutIcon />
        <Body3 bold style={{ marginLeft: "10.25px" }}>
          Log In
        </Body3>
      </div>
    </div>
  ) : (
    <div
      className="d-flex flex-direction-row justify-content-center"
      onClick={() => logout()}
      style={{
        marginRight: "50px",
        backgroundColor: theme.VERY_LIGHT_GRAY_COLOR,
        width: "116px",
        height: "40px",
        borderRadius: "8px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <LogoutIcon />
        <Body3 bold style={{ marginLeft: "10.25px" }}>
          Log Out
        </Body3>
      </div>
    </div>
  );
};
