import { Heading } from "../atoms/Heading"
import * as theme from "../Variables";

interface IConfigInputProps {
    minValue?: string;
    maxValue?: string;
    updateCallback?: any;
    placeHolderValue?: number;
}

export const ConfigInput = (props: IConfigInputProps) => {


    function validateRange(input: any) {
        if(input.target.value >= Number(props.maxValue)) {
            input.target.value = Number(props.maxValue);
        }
        else if (input.target.value < 0) {
            input.target.value = Number(props.minValue);
        }
    }

    return (
            <Heading dark lightFont>
                <input style={{maxWidth: "100px", backgroundColor: theme.TILE_COLOR, border: "none", outline: "none", textAlign:"right"}} min={props.minValue} max={props.maxValue} maxLength={3} placeholder={props.placeHolderValue?.toString()} type="number"  onInput={validateRange} onChange={props.updateCallback}></input> 
            </Heading>
    )
}