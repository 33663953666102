import * as theme from "../styles/Variables";
import Icon from "./Icon";
import styled from "styled-components";

const Svg = styled(Icon)`
    width: 17.89px;
    height: 25px;
    fill: ${theme.DATA_ICON_COLOR}
`

export const HumidityIcon = () => {
    return (
    <Svg xmlns="http://www.w3.org/2000/svg">
        <path xmlns="http://www.w3.org/2000/svg" d="M16.4297 11.2308L9.8421 0.674799C9.74652 0.539808 9.62026 0.429761 9.47385 0.35383C9.32743 0.277898 9.1651 0.238281 9.00039 0.238281C8.83568 0.238281 8.67335 0.277898 8.52693 0.35383C8.38052 0.429761 8.25426 0.539808 8.15868 0.674799L1.5413 11.2808C0.621971 12.7731 0.109872 14.4829 0.0566406 16.2378C0.0566406 18.6247 0.998926 20.9139 2.6762 22.6018C4.35348 24.2896 6.62836 25.2378 9.00039 25.2378C11.3724 25.2378 13.6473 24.2896 15.3246 22.6018C17.0019 20.9139 17.9441 18.6247 17.9441 16.2378C17.887 14.4636 17.3645 12.7362 16.4297 11.2308ZM9.00039 23.2378C7.15621 23.2354 5.38823 22.4972 4.08419 21.1849C2.78016 19.8727 2.04651 18.0936 2.04414 16.2378C2.09696 14.8394 2.51439 13.4796 3.25453 12.2948L4.18368 10.8048L14.1947 20.8788C13.5445 21.6201 12.7452 22.2138 11.8497 22.6207C10.9542 23.0276 9.98294 23.2373 9.00039 23.2378Z"/>
    </Svg>
    )
}