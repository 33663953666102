import * as theme from "../styles/Variables";
import Icon from "./Icon";
import React from "react";
import styled from "styled-components";

const Svg = styled(Icon)`
  width: 18px;
  height: 14px;
  fill: none;
`;

export const UpdateIcon = () => (
<div style={{
             display: "flex", 
             justifyContent: "center", 
             alignItems: "center",
             borderRadius: "5px"}}>
    <Svg xmlns="http://www.w3.org/2000/svg"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
      <path xmlns="http://www.w3.org/2000/svg" d="M14.625 7C14.625 6.07606 14.5902 5.16024 14.5218 4.2538C14.4096 2.76569 13.2343 1.59043 11.7462 1.47818C10.8398 1.40981 9.92394 1.375 9 1.375C8.07606 1.375 7.16024 1.40981 6.2538 1.47818C4.76569 1.59043 3.59043 2.76569 3.47818 4.2538C3.46573 4.41889 3.45439 4.58429 3.44417 4.75M14.625 7L16.875 4.75M14.625 7L12.375 4.75M3.375 7C3.375 7.92394 3.40981 8.83976 3.47818 9.7462C3.59043 11.2343 4.76569 12.4096 6.2538 12.5218C7.16024 12.5902 8.07606 12.625 9 12.625C9.92394 12.625 10.8398 12.5902 11.7462 12.5218C13.2343 12.4096 14.4096 11.2343 14.5218 9.7462C14.5343 9.58111 14.5456 9.41571 14.5558 9.25M3.375 7L5.625 9.25M3.375 7L1.125 9.25" stroke="#1A1D1F"/>
    </Svg>
</div>
);
