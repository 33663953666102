import * as theme from "../Variables";

import styled from "styled-components";

interface Body2Props {
  readonly dark?: boolean;
  readonly bold?: boolean;
}

export const Body2 = styled.div<Body2Props>`
  font-family: "Inter", serif;
  font-weight: ${(props) => (props.bold? "500" : "200")};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: ${(props) => (props.dark ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
`;
