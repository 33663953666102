import styled from "styled-components";

export const ConfigHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    height: 40px;
`;

export const ConfigEntry = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ConfigRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 336px;
    align-items: center;
`;

export const ConfigPanel = styled.div`
    height: 430px;
    width: 483px;
    display:flex;
    flex-direction: column;
    margin-left: 31px;
    margin-top: 34px;
`;

export const ConfigCard = styled.div`
    height: 430px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    margin-bottom: 10px;
`;