import * as theme from "../Variables";

import styled from "styled-components";

interface HeadingProps {
  readonly dark?: boolean;
  readonly lightFont?: boolean;
}

export const Heading = styled.h1<HeadingProps>`
  font-family: "Inter", serif;
  font-weight: ${(props: any) =>
    props.lightFont ? "100" : "800" };
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -2%;
  color: ${(props: any) =>
    props.dark ? theme.BLACK_COLOR : theme.WHITE_COLOR };
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
`;
