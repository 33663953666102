import { Stack } from "react-bootstrap";
import { PropsWithChildren } from "react";
import background from "../assets/images/aeolus-background.png";
import { NavigationBar } from "./NavigationBar";

export const PageWrapper = (props: PropsWithChildren) => {
  return (
    <>
      <div
        style={{
          margin: "0px",
          backgroundImage: `url(${background})`,
          position: "relative",
          backgroundRepeat: "repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundAttachment: "scroll",
          flex: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
          <NavigationBar/>
          <Stack className="h-100">{props.children}</Stack>
      </div>
    </>
  );
};
