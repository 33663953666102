import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DeviceManager } from "../shared/services/DeviceManager";
import { DeviceServiceClient } from "../proto/device_service.client";
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { DeviceCard } from "./DeviceCard";
import { SensorServiceClient } from "../proto/sensor_service.client";
import { bind } from "@react-rxjs/core";
import { DeviceType } from "../shared/services/Device";
import { first } from "rxjs";

const transport = new GrpcWebFetchTransport({
  baseUrl: process.env.REACT_APP_BACKEND ?? "http://localhost:8080",
});
const deviceClient = new DeviceServiceClient(transport);
const sensorClient = new SensorServiceClient(transport);

const deviceManager = new DeviceManager(deviceClient, sensorClient)

const [useDevices, devices$] = bind(deviceManager.devices$)

export const Aeolus = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const devices = useDevices()

  useEffect(() => {
    if (isAuthenticated && !isLoading)
    {
      setTimeout(() => deviceManager.start(getAccessTokenSilently), 3000)
    }
    console.log("Backend address: ", process.env.REACT_APP_BACKEND);
  }, [isAuthenticated, isLoading]);

  return (
    <>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        {/* Sort once by last seen, but don't re-order as that changes */}
        {devices.valueSeq().sort((x, y) => (x.lastDetails?.status?.lastSeen ?? 0) > (y.lastDetails?.status?.lastSeen ?? 0) ? -1 : 1).map((device) => {
          if (
            device.type == DeviceType.Aeolus ||
            device.type == DeviceType.Diialog
          ) {
            return (
              <div
                key={device.id}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DeviceCard
                  device={device}
                ></DeviceCard>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};
