
export interface ConfigurationConstants {
    name: string;
    minValue: string;
    maxValue: string;
    units: string
}

export interface ConfigParams {
    publishInterval: ConfigurationConstants;
    onDuration: ConfigurationConstants;
    offDuration: ConfigurationConstants;
}

export const CONFIGURATION_PARAMS: ConfigParams = {
    publishInterval: {
        name: "Publishing Interval",
        minValue: "10",
        maxValue: "100",
        units: "sec"
    },
    onDuration: {
        name: "On Duration",
        minValue: "3",
        maxValue: "100",
        units: "min"
    },
    offDuration: {
        name: "Off Duration",
        minValue: "1",
        maxValue: "100",
        units: "min"
    }
}