import { Navbar } from "react-bootstrap";
import { LoginOutButton } from "./LoginOutButton";
import * as theme from "../assets/styles/Variables";
import { AeolusIcon } from "../assets/icons/AeolusIcon";

export const NavigationBar = () => {
  return (
    <Navbar
      // bg="dark"
      // variant="dark"
      expand
      className="d-flex justify-content-between"
      style={{ backgroundColor: theme.BACKGROUND_COLOR, height: "162px" }}
    >
      <div
        className="d-flex, flex-direction-column"
        style={{ marginLeft: "64px" }}
      >
        <AeolusIcon />
        {/* <div className="d-flex flex-direction-row">
          <AeolusIcon />
          <Title primary style={{marginBottom: "0px"}}> Aeolus </Title>
        </div> */}
      </div>
      <LoginOutButton />
    </Navbar>
  );
};
