import { AnySoaRecord } from "dns";
import * as theme from "../styles/Variables";
import Icon from "./Icon";
import React from "react";
import styled from "styled-components";

const Svg = styled(Icon)`
  width: 16px;
  height: 16px;
  fill: none;
`;

export const LogoutIcon = (props: any) => (
<div style={{
             display: "flex", 
             justifyContent: "center", 
             alignItems: "center",
             borderRadius: "5px"}}>
    <Svg xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
      <path d="M8.5625 10.25L10.8125 8M10.8125 8L8.5625 5.75M10.8125 8L5.1875 8M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z" stroke="#1A1D1F"/>
    </Svg>
</div>
);