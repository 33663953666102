import * as theme from "../Variables";

import styled from "styled-components";

interface TitleProps {
  readonly primary?: boolean;
}
export const Title = styled.h2<TitleProps>`
  font-family: "inter", serif;
  font-size: 84px;
  font-weight: 800;
  line-height: 90px;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.primary ? theme.PRIMARY_COLOR : theme.WHITE_COLOR};
`;
