import {
  DeviceStyle,
  DeviceFooter,
  DeviceFooterComponent,
  DeviceHeading,
  DeviceStatusCollapsed,
  DeviceStatusExpanded,
} from "../assets/styles/Device";
import { Body1, Body3, Heading } from "../assets/styles";
import * as theme from "../assets/styles/Variables";
import { useEffect, useState } from "react";
import moment from "moment";
import { DataTile } from "./DataTile";
import { DevicePresence } from "../proto/common";
import { ConfigurationUpdate } from "./ConfigurationUpdate";
import { Device, DeviceType } from "../shared/services/Device";
import { bind } from "@react-rxjs/core";
import { ISensorReadingsSource } from "../shared/services/SensorReadingsSource";

const [useDeviceDetails, deviceDetails$] = bind((device: Device) => device.details$)

export interface IDeviceCardProps {
  device: Device;
}

export const DeviceCard = (props: IDeviceCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [sensorReadingsSource, setSensorReadingsSource] = useState<ISensorReadingsSource>()
  const deviceDetails = useDeviceDetails(props.device)

  function getFormatedDateString(lastSeen?: string): string {
    return moment(lastSeen).format("llll");
  }

  useEffect(() => {
    // When this is re-created and pushed down to children, that forces all subscriptions on the old source
    // to be cancelled, which we don't want. Therefore keep this around as long as we can.
    setSensorReadingsSource(props.device.createSensorReadingsSource())
  }, [props.device])

  return (
    <DeviceStyle
      height={isExpanded ? "auto" : "100px"}
      style={{
        backgroundColor: isExpanded ? theme.LIGHT_CARD_COLOR : theme.CARD_COLOR,
        display: "flex",
        flexDirection: "column",
        maxWidth: "1600px",
        borderRadius: "25px",
      }}
    >
      <DeviceHeading style={{}}>
        {!isExpanded && (
          <DeviceStatusCollapsed
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ cursor: "pointer" }}
          >
            <Heading>{props.device.id}</Heading>
            <Body1 primary={deviceDetails?.status?.presence === DevicePresence.DevicePresence_ONLINE}>
              {deviceDetails?.status?.presence === DevicePresence.DevicePresence_ONLINE
                ? "Online"
                : "Offline"}
            </Body1>
          </DeviceStatusCollapsed>
        )}

        {isExpanded && (
          <DeviceStatusExpanded
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ cursor: "pointer" }}
          >
            <Body1 dark style={{ marginTop: "31px" }}>
              {deviceDetails?.status?.presence === DevicePresence.DevicePresence_ONLINE
                ? "Online"
                : "Offline"}
            </Body1>
            <Heading dark>{props.device.id}</Heading>
          </DeviceStatusExpanded>
        )}

        {/* <div onClick={() => setIsEditing(!isEditing)} style={{marginRight: "29px"}}>
          <EditIcon></EditIcon>
        </div> */}
      </DeviceHeading>

      {isExpanded && props.device.type == DeviceType.Aeolus && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginLeft: "50px",
              marginRight: "50px",
              marginTop: "15px",
            }}
          >
            {sensorReadingsSource?.data.keySeq().map(sensorType =>
              <DataTile
                key={sensorType}
                sensorType={sensorType}
                source={sensorReadingsSource} />
            )}
          </div>

          <div
            className="d-flex flex-direction-row justify-content-center"
            style={{
              marginLeft: "50px",
              marginRight: "50px",
            }}
          >
            <ConfigurationUpdate
              device={props.device}
            ></ConfigurationUpdate>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <DeviceFooter>
              <DeviceFooterComponent>
                <Body3 bold>Firmware Version</Body3>
                <Body3 style={{ marginLeft: "20px" }}>
                  {" "}
                  {deviceDetails?.status?.firmwareVersion}
                </Body3>
              </DeviceFooterComponent>
              <DeviceFooterComponent style={{ marginLeft: "55px" }}>
                <Body3 bold>Last Seen</Body3>
                <Body3 style={{ marginLeft: "20px" }}>
                  {" "}
                  {getFormatedDateString(deviceDetails?.status?.lastSeen)}
                </Body3>
              </DeviceFooterComponent>
            </DeviceFooter>
          </div>
        </>
      )}
      {isExpanded && props.device.type == DeviceType.Diialog && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginLeft: "50px",
              marginRight: "50px",
              marginTop: "15px",
            }}
          >
            {sensorReadingsSource?.data.keySeq().map(sensorType =>
              <DataTile
                key={sensorType}
                sensorType={sensorType}
                source={sensorReadingsSource} />
            )}
          </div>

          {/* <div
            className="d-flex flex-direction-row justify-content-center"
            style={{
              marginLeft: "50px",
              marginRight: "50px",
            }}
          >
            <ConfigurationUpdate
              device={props.deviceDetails}
            ></ConfigurationUpdate>
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <DeviceFooter>
              <DeviceFooterComponent>
                <Body3 bold>Firmware Version</Body3>
                <Body3 style={{ marginLeft: "20px" }}>
                  {" "}
                  {deviceDetails?.status?.firmwareVersion}
                </Body3>
              </DeviceFooterComponent>
              <DeviceFooterComponent style={{ marginLeft: "55px" }}>
                <Body3 bold>Last Seen</Body3>
                <Body3 style={{ marginLeft: "20px" }}>
                  {" "}
                  {getFormatedDateString(deviceDetails?.status?.lastSeen)}
                </Body3>
              </DeviceFooterComponent>
            </DeviceFooter>
          </div>
        </>
      )}
    </DeviceStyle>
  );
};
