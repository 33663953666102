import * as theme from "../Variables";

import styled from "styled-components";

interface Body1Props {
  readonly dark?: boolean;
  readonly primary?: boolean
}

export const Body1 = styled.div<Body1Props>`
  font-family: "inter", serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.primary ? theme.PRIMARY_COLOR : props.dark ? theme.BLACK_COLOR : theme.WHITE_COLOR};
`;
