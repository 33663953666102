import { ConfigEntry, ConfigRow } from "../assets/styles/Config";
import * as theme from "../assets/styles/Variables";
import { Body3 } from "../assets/styles";
import Divider from '@mui/material/Divider';
import { ConfigInput } from "../assets/styles/molecules/ConfigInput";
import { ConfigurationConstants } from "../shared/constants/ConfigConstants";

interface IConfigProps {
  readonly breakBar?: boolean;
  deviceId?: string;
  configLabels: ConfigurationConstants;
  updateCallback?: any;
  placeHolderValue?: number;
};

export const ConfigEdit = (props: IConfigProps) => {

  return (
    <div className="d-flex flex-direction-row justify-content-center">
      <ConfigEntry>
        <div className="d-flex flex-direction-row justify-content-center">
          <ConfigRow>
            <Body3 info className="d-flex flex-direction-row justify-content-center" style={{width: "75px"}}> {props.configLabels.minValue} - {props.configLabels.maxValue} </Body3>
            <ConfigInput placeHolderValue={props.placeHolderValue} updateCallback={props.updateCallback} minValue={props.configLabels.minValue} maxValue={props.configLabels.maxValue} />
            <Body3 info className="d-flex flex-direction-row justify-content-center" style={{width: "75px"}}> {props.configLabels.units} </Body3>
          </ConfigRow>
        </div>
        <Body3 info className="d-flex flex-direction-row justify-content-center"> {props.configLabels.name} </Body3>
        {props.breakBar && <Divider variant="middle" sx={{ bgcolor: theme.FAINT_GRAY_COLOR, width: "363px", height:"1px", marginTop: "10px", marginBottom:"10px" }} flexItem />}
      </ConfigEntry>
    </div>
  )
}