import { PageWrapper } from "./components/PageWrapper";
import { Aeolus } from "./components/Aeolus";
import { Subscribe } from "@react-rxjs/core";

function App() {
  return (
    <PageWrapper>
      <Subscribe>
        <Aeolus />
      </Subscribe>
    </PageWrapper>
  );
}

export default App;
